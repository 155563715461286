<template>
  <div class="banner w100 rowd space-around align-center">
    <div class="text-start banner-title columnd">
      <h1 class="c-lightOrange f36-700">Contact Us</h1>
      <h4 class="f18-400 c-ddGrey">
        Radindev trading platform for pere to pere exchanges in fastest way
      </h4>
      <p class="c-medGrey f14-400">
        Behind KuCoin are two tech geeks who were early blockchain adopters.
        Having started coding at the age of 8 and founded his first startup at
        the age oft the time was difficult for beginners to use.
      </p>
      <div class="infos columnd">
        <div class="info rowd">
          <inline-svg :src="require('../../assets/Icons/locationsvg.svg')" />
          <span class="f14-400"> Country, City, Sate, Street , </span>
        </div>
        <div class="info rowd">
          <inline-svg :src="require('../../assets/Icons/Phoesvg.svg')" />
          <span class="f14-400"> +99- 8378839 </span>
        </div>
        <div class="social rowd">
          <inline-svg
            class="fill-black"
            :src="require('../../assets/Icons/facebook.svg')"
          />
          <inline-svg :src="require('../../assets/Icons/instagram.svg')" />
          <inline-svg
            class="fill-black"
            :src="require('../../assets/Icons/twitter.svg')"
          />
          <inline-svg :src="require('../../assets/Icons/whatsApp.svg')" />
        </div>
      </div>
    </div>
    <div class="auth-form-container columnd">
      <h1 class="text-start f28-700 c-darkBlue">Get in touch with us</h1>
      <div class="inputs columnd">
        <div class="input-container">
          <span> Name </span>
          <input type="text" />
        </div>
        <div class="input-container">
          <span> Email </span>
          <input type="text" />
        </div>
        <div class="input-container">
          <span> Message </span>
          <textarea rows="10" type="text"></textarea>
        </div>
        <button class="primary-btn">Send Message</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
}
.social {
  gap: 30px;
  svg {
    cursor: pointer;
  }
}
.infos {
  padding: 24px;
  background-color: var(--lightGrey);
  border-radius: 24px;
  gap: 16px;
}
.info {
  gap: 4px;
  align-items: center;
}
.banner-title {
  gap: 12px;
  max-width: 420px;
}
.banner {
  position: relative;
  height: 100vh;
  gap: 50px;
  &::before {
    content: " ";
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../../assets/Background/PublicBanner.svg");
    background-size: 100%;
    z-index: -1;
    // transform: translate(10px, -60px) scale(1.2, 1.01);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
@media only screen and(max-width:1024px) {
  .banner::before {
    content: none;
  }
  .banner {
    flex-direction: column !important;
    padding: 10px;
    padding-top: 100px;
    height: unset;
  }
  .banner-title {
    transform: unset !important;
    display: unset !important;
  }
  .find-order {
    transform: unset !important;
  }
}
</style>
